<template>
  <div id="onlineAppointment" style="justify-content: center center">
    <div>
      <div class="vx-row">
        <feather-icon
          @click="back()"
          :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
          style="width: 30px; cursor: pointer"
        >
        </feather-icon>
        <p class="text-xl">
          {{ $t("OnlineAppointment") }}
        </p>
      </div>
      <div class="mt-5" style="justify-content: center center">
        <div class="vx-row w-full">

          <div class="vx-col lg:w-1/4 mt-5 w-full">
            <v-date-picker
              :locale="$vs.rtl ? 'ar' : 'en'"
              @input="getAppointmentSessions(true)"
              v-model="appointmentDate"
              :attributes="attrs"
              @update:from-page="pageChange"
            />
          </div>
          <div class="vx-col lg:w-3/4 w-full MyTable mt-5 ">
            <table class="w-full ">
              <tr style=" height: 50px" class="HeaderTableColor">
                <th class="h-25 text-center">
                  {{ $t("Patient") }}
                </th>
                <th class="h-25 text-center">
                  {{ $t("Date") }}
                </th>
                <th class="h-25 text-center">
                  {{ $t("Time") }}
                </th>
                <th class="h-25 text-center">
                  {{ $t("Action") }}
                </th>
              </tr>
              <tr :data="tr" :key="indextr" v-for="(tr, indextr) in Model">
                <!--v-show="currentDate(tr.DoctorSession.DayUTC)"-->
                <td>
                  <div @click="GotoSessionDetails(tr)">

                    <u style="color: primary" v-if="tr.Patient&&(tr.StatusID == 2||tr.StatusID == 1)">{{
                      tr.Patient.Name
                    }}</u>
                    <p v-else> {{ tr.Patient.Name}}</p>

                  </div>
                </td>
                <td>
                  <p class="product-name font-medium truncate" type="date">
                    {{ tr.DoctorSession.DateFormatted }}
                  </p>
                </td>
                <td>
                  <p class="product-name font-medium truncate">
                    {{ tr.DoctorSession.DateFrom.slice(11, 16) }}
                  </p>
                 </td>
<!--&&checkDate(tr.DoctorSession.DateFrom) -->
                <td>
                  <div v-if="(tr.StatusID == 2||tr.StatusID == 1) " :disabled="( tr.DoctorSession.DateFormatted  > new Date().toISOString().slice(0, 10))" >
                    <vx-tooltip :text="$t('Complete')" position="top">
                      <feather-icon
                        @click="CompletePatientReservation(tr)"
                        icon="CheckIcon"

                      ></feather-icon>
                    </vx-tooltip>
                  </div>
                  <div v-else>
                    <p class="product-name font-medium truncate" v-if="tr.Status != null">
                      {{ tr.Status.Name }}
                    </p>
                  </div>

                </td>
                <!-- <td v-else>
                  <p class="product-name font-medium truncate">
                    {{ tr.Status.Name }}
                  </p>
                </td> -->
              </tr>
              <tr v-if="Model.length == 0 ">
                <td></td>
                <td>
                  <h3 class="w-full sm:w-auto text-center text-primary mt-5">
                    {{ $t("NoDataToshow") }}
                  </h3>
                </td>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  data() {
    return {
      IsDoctorRequest:true,
      EnglishLan: false,
      baseURL: domain,
      Model: [],
      monthSessions: [],
      date: new Date(),
      appointmentDate:null,
      appointmentDateEnd: new Date(),
       isHidden: true,

      // currentDate:new Date().toISOString().slice(0, 10),
      currentDate:new Date().toLocaleString(),

    };
  },
  computed: {

    attrs() {
      return [
        {
          bar: "green",
          // dates: this.$store.state.DoctorList.monthPatientReservation.map(ob=>ob.DoctorSession.DayUTC),
          dates: this.monthSessions.map((ob) => ob.DoctorSession.DateFrom),
        },
      ];
    },
  },
  components: {
    Datepicker,
  },
  methods: {
 checkDate(sesstionDate) {
      debugger;
      const current = new Date();
      sesstionDate = new Date(sesstionDate);
      var diffMs=sesstionDate-current;
      var diffMins = Math.round(diffMs / 60000);

      if (diffMins <= 10)
           return true;

      else return false;
    },
    // CompareDate(DoctorSessionDateFrom)
    // {
    //  DoctorSessionDateFrom = tr.DoctorSession.DateFormatted;
    // },

    pageChange(obj) {
      debugger;
      this.sessions=[];
      debugger;
      var search = {
        DateFrom: obj.year + "/" + obj.month + "/1",
        To: new Date(obj.year, obj.month, 0),
        PaymentStatusID : 5
      };
      search.To.setDate(search.To.getDate() + 1)
      search.DateTo =  moment(search.To).format("LL");
       this.date = search.DateFrom;
      // this.appointmentDateEnd = this.date;
      //this.getAppointmentSessions(null);
      search.doctorID = this.$route.params.ID;
      this.$store
        .dispatch("DoctorList/getPatientReservation", search)
        .then((res) => {
          debugger
          this.monthSessions = res.data.Data;
          this.Model = res.data.Data;
        });
      // this.getAppointmentSessions(false);
      // this.$store.dispatch("DoctorList/getPatientReservationCalender",search);
    },
    back() {
      this.$router.go(-1);
    },
    GotoSessionDetails(reservation) {
      debugger;
  if(reservation.StatusID==2||reservation.StatusID==1)
      this.$router.push({
        name: "DoctorSessionDetails",
        params: { PatientReservationID: reservation.ID ,IsDoctorRequest:this.IsDoctorRequest},
      });
    },
    currentDate(date) {
      debugger;
      const current = new Date();

      if (date > current) {
        debugger;
        return true;
      } else {
        debugger;
        return false;
      }
    },
    addHours(date, h) {
      if (typeof date === "string") date = new Date(date);
      date.setTime(date.getTime() + h * 60 * 60 * 1000);
      return date;
    },
    getAppointmentSessions(isOneDay) {
      // this.appointmentDate = selectedDay;
      debugger;
      var searchAppointment = {};
      searchAppointment.PaymentStatusID = 5;
      if (this.$route.params.ID>0) {

        if(isOneDay==true){
        searchAppointment.DateFrom = moment(this.appointmentDate).format("LL");

        }
        else{
          this.date = new Date(this.date)
        searchAppointment.DateFrom = new Date(this.date.getFullYear(),this.date.getMonth(),1);
        searchAppointment.DateTo = new Date(this.date.getFullYear(),this.date.getMonth(),31);

        searchAppointment.DateFrom = moment(searchAppointment.DateFrom).format("LL");
        searchAppointment.DateTo.setDate(searchAppointment.DateTo.getMonth() + 1)
        searchAppointment.DateTo = moment(searchAppointment.DateTo).format("LL");
        }

        searchAppointment.doctorID = this.$route.params.ID;
        //searchAppointment.NotIncludeStatusIds=[1]//pending

        this.$vs.loading();
        this.$store
          .dispatch("DoctorList/getPatientReservation", searchAppointment)
          .then((res) => {
            debugger;
            this.Model = res.data.Data;
            debugger;
            this.$vs.loading.close();
            if ((this.Model == null || this.Model.length == 0 ) &&  this.appointmentDate !=null) {
              this.$vs.notify({
                title: this.$t("NoData"),
                text: this.$t("NoDataToshow"),
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning",
              });
            }
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
    CompletePatientReservation(data) {
      debugger
       var dayNow = new Date();
      var myDate = new Date(data.DoctorSession.DateFrom);
      dayNow = new Date(dayNow);
      var date1 = dayNow.getTime();
      var date2 = myDate.getTime();
      if (date1 < date2) {
        this.$vs.notify({
          color: "warning",
          title: this.$t('CanNotCompleteSession'),
          text: this.$t("CanNotCompleteFutureSession"
          ),
        });
      }
      else{
this.$store
        .dispatch("DoctorList/CompletePatientReservation",  data.ID)
        .then((res) => {
          this.$vs.notify({
            title: this.$t("success"),
            text: res.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });

          this.getAppointmentSessions(false);
        })
        .catch((err) => {
          debugger;
          this.$vs.notify({
            title: this.$t("Error"),
            text: err.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
      }


   },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    this.getAppointmentSessions(false);
  },
};
</script>
<style>
.MyTable table {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  background: white;
}
.MyTable th,
.MyTable td {
  border: 1px solid #85828220;
  border-collapse: collapse;
  text-align: center;

  height: 50px;
}

.mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
#onlineAppointment .login-tabs-container {
  min-height: 505px;
}
.con-tab {
  padding-bottom: 14px;
}

.con-slot-tabs {
  margin-top: 1rem;
}
.background {
  color: rgb(255, 9, 9);
}
#onlineAppointment .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
.head {
  color: white;
  text-align: center;
  margin-top: 20px;
}
</style>
